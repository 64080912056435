/* eslint-disable comma-dangle */
export const TABBAR = {
  SET_SHOW: 'SET_SHOW_TABBAR',
  SET_SHOW_DOT: 'SET_SHOW_TABBAR_DOT',
  SET_BADGE: 'SET_TABBAR_BADGE',
  SET_NEW_LIST: 'SET_TABBAR_NEW_LIST',
}

export const NAVBAR = {
  SET_SHOW: 'SET_HOW_NAVBAR',
  SET_TITLE: 'SET_NAVBAR_TITLE',
  SET_LEFT_TEXT: 'SET_NAVBAR_LEFT_TEXT',
  SET_SHOW_LEFT_ARROW: 'SET_SHOW_NAVBAR_LEFT_ARROW',
  SET_RIGHT_TEXT: 'SET_NAVBAR_RIGHT_TEXT',
  SET_RIGHT_ICON: 'SET_NAVBAR_RIGHT_ICON',
  RESET_CONFIG: 'SET_RESET_NAVBAR_CONFIG',
  ON_LEFT_CLICK: 'ON_NAVBAR_LEFT_CLICK',
  ON_RIGHT_CLICK: 'ON_NAVBAR_RIGHT_CLICK',
}
