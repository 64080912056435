<template>
  <div class="base-layout">
    <!-- <van-nav-bar
      v-if="navBar.show"
      :title="navBar.title"
      :left-arrow="!tabBar.show && navBar.leftArrow"
      :left-text="navBar.leftText"
      :right-text="navBar.rightText"
      :border="navBar.border"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    > -->
      <!-- logo -->
      <!-- <template #left>
        <img class="ourlogo" src="../assets/logo/logotitle.png" alt="logo" />
      </template> -->

      <!-- <template #right>
        <van-dropdown-menu :overlay=false :z-index="5200" @touchstart="handlerTouchstart">
          <van-dropdown-item title="湖北">
            <van-cell title="请选择您需要报考所在的省市" class="tip" />
            <van-cell-group>
              <van-cell
                v-for="(item, id) in citys"
                :key="id"
                @touchstart="handlerTouchstart"
                @touchmove="handlerTouchmove"
                @touchend="handlerTouchend"
              >
                <template #title>
                  {{ item }}
                </template>
              </van-cell>
            </van-cell-group>
          </van-dropdown-item>
        </van-dropdown-menu>
      </template> -->
      <!-- 标题（没用上） -->
      <!-- <template v-if="navBar.rightIcon" #right>
        <span v-if="navBar.rightText" class="van-nav-bar__text">{{
          navBar.rightText
        }}</span>
        <van-icon :name="navBar.rightIcon" size="18" />
      </template> -->
    <!-- </van-nav-bar> -->

    <!-- 省份选择 -->
    <!-- <div class="citys">
      <van-dropdown-menu :overlay="false">
        <van-dropdown-item :title="citychoose">
          <van-cell title="请选择您需要报考所在的省市" class="tip" />
          <van-cell-group>
            <van-cell
              center
              v-for="(item, id) in citys"
              :key="id"
              @click="seed(item)"
              @touchstart="handlerTouchstart"
              @touchmove="handlerTouchmove"
              @touchend="handlerTouchend"
            >
              <template #title>
                {{ item }}
              </template>
            </van-cell>
          </van-cell-group>
        </van-dropdown-item></van-dropdown-menu
      >
    </div> -->

    <div class="container">
      
        <router-view />
      
    </div>

    <!-- <van-tabbar
      v-if="tabBar.show"
      v-model="active"
      :border="tabBar.border"
      :active-color="tabBar.activeColor"
      :inactive-color="tabBar.inactiveColor"
      :safe-area-inset-bottom="tabBar.safeAreaInsetBottom"
      :fixed="false"
      route
      @change="onTabBarChange"
    >
      <van-tabbar-item
        v-for="item of tabBar.list"
        :key="item.key"
        :to="item.path"
        :icon="item.icon"
        replace
        >{{ item.title }}</van-tabbar-item
      >
    </van-tabbar> -->
  </div>
</template>

<script>
// utils
import { mapGetters } from "vuex";
// components
import {
  NavBar,
  Tabbar,
  TabbarItem,
  Icon,
  DropdownMenu,
  DropdownItem,
  Cell,
  CellGroup,
} from "vant";
// others
function notUndefined(val) {
  return typeof val !== "undefined";
}
// vm
export default {
  components: {
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Icon.name]: Icon,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  computed: {
    ...mapGetters(["navBar", "tabBar"]),
  },
  watch: {
    // 每当 route 改变时，进行一些处理
    $route: {
      handler(route) {
        // 先重置 nav bar 设置
        this.$navBar.resetConfig();
        // 根据 route meta 设置一些默认值
        const {
          title,
          showTabBar,
          hiddenNavBar,
          navBarLeftText,
          navBarHiddenLeftArrow,
          navBarRightText,
          navBarRightIcon,
        } = route.meta;
        this.$tabBar.setShow(showTabBar);
        // 如果显示 tab bar 就不需要默认展示内页的相关配置
        if (showTabBar) {
          this.$navBar.setLeftText("");
          this.$navBar.setRightText("");
          this.$navBar.setRightIcon("");
        }
        if (hiddenNavBar) {
          this.$navBar.setShow(false);
        } else {
          // 显示 nav bar 时再进行相关设置
          notUndefined(title) && this.$navBar.setTitle(title);
          notUndefined(navBarLeftText) &&
            this.$navBar.setLeftText(navBarLeftText);
          notUndefined(navBarHiddenLeftArrow) &&
            this.$navBar.setShowLeftArrow(false);
          notUndefined(navBarRightText) &&
            this.$navBar.setRightText(navBarRightText);
          notUndefined(navBarRightIcon) &&
            this.$navBar.setRightIcon(navBarRightIcon);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      active: 0,
      value1: 0,
      value2: "a",
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      option2: [
        { text: "默认排序", value: "a" },
        { text: "好评排序", value: "b" },
        { text: "销量排序", value: "c" },
      ],
      citychoose: "湖北",
      citys: [
        "安徽",
        "北京",
        "重庆",
        "福建",
        "甘肃",
        "广东",
        "广西",
        "贵州",
        "海南",
        "河北",
        "黑龙江",
        "河南",
        "湖北",
        "湖南",
        "江苏",
        "江西",
        "吉林",
        "辽宁",
        "内蒙古",
        "宁夏",
        "青海",
        "山东",
        "上海",
        "陕西",
        "山西",
        "四川",
        "天津",
        "新疆",
        "云南",
        "浙江",
      ],
    };
  },
  methods: {
    onClickLeft() {
      // let flag = true;
      // const cb = this.navBar.handleLeftClick;
      // const next = (bool = true) => (flag = bool);
      // cb && cb(next);
      // flag && this.$router.go(-1);
      this.$router.go('home')
    },
    onClickRight() {
      const cb = this.navBar.handleRightClick;
      cb && cb();
      // console.log(cb);
      // console.log('1');
    },
    onTabBarChange(index) {
      // console.log(index, this.tabBar.list[index]);
    },
    handlerTouchstart() {
      console.log("触摸开始");
    },
    handlerTouchmove() {
      console.log("move");
    },
    handlerTouchend() {
      console.log("emd");
    },

    seed(i) {
      console.log("seed");
      this.citychoose = i;
    },
  },
};
</script>

<style lang="less" scoped>
.base-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  .ourlogo {
    width: 130px;
  }
  .container {
    height: 100%;
    flex: 1;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    .animate-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
.citys {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1000;
}
/deep/ .van-dropdown-item__content {
  position: absolute;
  max-height: 90%;
}

.van-cell {
  width: 100px;
  height: 40px;
  color: #8a9095;
  border: 1px solid #dee2ea;
  border-radius: 8px;
}
.van-cell-group {
  height: 600px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
}
/deep/ .van-dropdown-menu__bar {
  position: relative;
  display: flex;
  height: 8.8vw;
  background-color: #f2f7ff;
  box-shadow: none;
  border-radius: 8px;
}
.van-dropdown-menu {
  width: 70px;
}
.van-dropdown-menu__item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: 10px;
  border-radius: 8px;
}
/deep/ .van-dropdown-menu__title {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0 2.133333vw;
  color: #323233;
  font-size: 4vw;
  line-height: 5.866667vw;
  margin-right: 5px;
}

.tip {
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px;
  color: #222;
}
</style>
