import Vue from "vue";
import VueRouter from "vue-router";

import BaseLayout from "@/layout/BaseLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: BaseLayout,
    name: "BaseLayout",
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
        meta: { title: "主页", showTabBar: true },
      },
      {
        path: "/teacher",
        name: "teacher",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/teacher.vue"),
        meta: { title: "师资", showTabBar: false },
      },
      // {
      //   path: "/about",
      //   name: "About",
      //   component: () =>
      //     import(/* webpackChunkName: "About" */ "@/views/About.vue"),
      //   meta: { title: "我的", showTabBar: true, hiddenNavBar: false },
      // },
      {
        path: "/examinfo",
        name: "examinfo",
        component: () =>
          import(
            /* webpackChunkName: "Home" */ "@/views/examinfo/examinfo.vue"
          ),
        meta: { title: "考试信息", showTabBar: false },
      },
      {
        path: "/lesson",
        name: "lesson",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/lesson/lesson.vue"),
        meta: { title: "所有课程", showTabBar: false },
      },
      {
        path: "/lesson/lessoninfo",
        name: "lessoninfo",
        component: () =>
          import(
            /* webpackChunkName: "Home" */ "@/views/lesson/lessoninfo.vue"
          ),
        meta: { title: "课程详情", showTabBar: false },
      },
      {
        path: "/pubcourse",
        name: "pubcourse",
        component: () =>
          import(
            /* webpackChunkName: "Home" */ "@/views/lesson/lessoninfotwo.vue"
          ),
        meta: { title: "课程详情2", showTabBar: false },
      },
      {
        path: "/lesson/pay",
        name: "pay",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/lesson/paylesson.vue"),
        meta: { title: "课程购买", showTabBar: false },
      },
      {
        path: "/pubcourseinfo",
        name: "pubcourseinfo",
        component: () =>
          import(
            /* webpackChunkName: "Home" */ "@/views/lesson/paylessontwo.vue"
          ),
        meta: { title: "课程报名", showTabBar: false },
      },
      {
        path: "/lesson/payfinisht",
        name: "payfinish",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/lesson/payfinish.vue"),
        meta: { title: "购买完成", showTabBar: false },
      },
      {
        path: "/recruit",
        name: "recruit",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/recruit/recruit.vue"),
        meta: { title: "火热招聘", showTabBar: false },
      },
      {
        path: "/about",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/about/about.vue"),
        meta: { title: "关于博轩", showTabBar: false },
      },

      {
        path: "/inner",
        name: "Inner",
        component: () =>
          import(/* webpackChunkName: "Inner" */ "@/views/Inner.vue"),
        meta: { title: "" },
        /// todo
        children: [
          {
            path: "/examinfosummery",
            name: "examinfosummery",
            component: () => import("@/views/inner/examinfosummery.vue"),
          },
          {
            path: "/examdatasummery",
            name: "examdatasummery",
            component: () => import("@/views/inner/examdatasummery.vue"),
          },
          {
            path: "/examinfodetail",
            name: "examinfodetail",
            component: () => import("@/views/inner/examinfodetail.vue"),
          },
          {
            path: "/examdatadetail",
            name: "examdatadetail",
            component: () => import("@/views/inner/examdatadetail.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  // base:'/dist',
  // base:'/home/mobile',
  routes,
});

export default router;
